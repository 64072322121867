<template>
  <v-container class="pa-4">
    <v-row justify="center" align-content="center">
        <v-col cols="12" align-self="center">
          <h3 align="center">TheTeam jamoasi va loyihaga o'z hissasini qo'shganlar</h3>
        </v-col>
        <v-col cols="12" align-self="center">
          <v-expansion-panels>
            <v-expansion-panel v-for="(message, i) in messages" :key="i" hide-actions>
              <v-expansion-panel-header expand-icon="fa-chevron-down">
                <v-row align="center" class="spacer" no-gutters>
                  <v-col cols="4" sm="2" md="1">
                    <v-avatar size="48px">
                      <img v-if="message.avatar" alt="Avatar" :src="message.avatar">
                    </v-avatar>
                  </v-col>
                  <v-col class="hidden-xs-only" sm="5" md="3">
                    <strong v-html="message.name"></strong>
                  </v-col>
                  <v-col class="text-no-wrap" cols="5" sm="3">
                    <strong v-html="message.title"></strong>
                  </v-col>
                  <v-col v-if="message.excerpt" class="grey--text text-truncate hidden-sm-and-down">&mdash; {{
                    message.excerpt }}
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-card-text v-text="lorem"></v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'about',

    data() {
      return {
        messages: [
          {
            avatar: 'https://thiscatdoesnotexist.com?v=3',
            name: 'Adizbek Egrashev',
            title: '@adizbek',
            excerpt: 'G\'oya muallifi, loyiha dasturchisi',
          },
          {
            avatar: 'https://thiscatdoesnotexist.com?v=2',
            name: 'Shamshod Jamolov',
            title: '@hsamshod',
            excerpt: 'loyiha dasturchisi',
          },
          {
            avatar: 'https://thiscatdoesnotexist.com?v=1',
            name: 'Muhammadamin Hasilov',
            title: '@XMA_0_0_0',
            excerpt: 'loyiha dasturchisi',
          },
        ],
        lorem: 'Here will be some info',
      }
    }
  }
</script>
