import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Transliterator from '../views/Transliterator.vue'
import About from '../views/About.vue'
import Soon from '../views/Soon.vue'

const routes = [
  {
    path: '/',
    alias: '/home',
    name: 'home',
    component: Transliterator,
  },
  {
    path: '/about',
    name: 'about',
    component: About,
  },
  {
    path: '/wiki',
    name: 'wiki',
    component: () => import(/* webpackChunkName: "about" */ '../views/Wiki.vue')
  },
  {
    path: '/api-docs',
    name: 'api-docs',
    component: () => import(/* webpackChunkName: "about" */ '../views/Api.vue')
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: Soon
  },
  {
    path: '/telegram',
    name: 'telegram',
    component: Soon
  },
  {
    path: '/browser',
    name: 'browser',
    component: Soon
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
