<template>
  <v-container>
    <div class="text-center my-5">
      <h3 class="font-weight-medium text-muted">Soon...</h3>
      <v-btn class="mt-3" depressed color="primary" to="home">Bosh sahifaga</v-btn>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'soon',
  }
</script>
