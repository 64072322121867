<template>
  <v-container class="pa-4">
    <v-row justify="center" align-content="center">
      <v-col cols="2" align-self="center">
        <h3>Transliteratsiya</h3>
      </v-col>
      <v-col cols="3" align-self="center">
        <v-select :items="actions"
                  item-value="id"
                  item-text="label"
                  v-model="form.action"
                  solo hide-details label="Tanlang"
                  dense prepend-inner-icon="fas fa-language" append-icon="fa-chevron-down"
        ></v-select>
      </v-col>
      <v-col cols="7"></v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-textarea v-model="form.text"
                    placeholder="Matnni kiriting..."
                    clearable clear-icon="fa-times-circle"
                    hide-details solo
                    @click:clear="result = ''"
        />
      </v-col>
      <v-col cols="6">
        <v-textarea v-model="result" placeholder="Natija"
                    :disabled="!result"
                    solo hide-details readonly
                    append-icon="fa-copy"
                    @click:append="clip"
        />
      </v-col>
      <v-col cols="12" class="text-center text--disabled">
        yoki
      </v-col>
      <v-col cols="12">
        <uploader :files.sync="form.files"></uploader>

        <div class="mt-2" v-for="file in resultFiles" :key="file">
          <h4 class="d-inline-block mr-8">Natija: </h4>
          <v-btn small dark depressed :href="getDownloadUrl(file)" color="blue">
            <v-icon left small>fa-download</v-icon>
            Yuklab olish
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12">
        <v-btn :disabled="!isValidForm" :loading="transliterating" @click="process" color="primary">Transliteratsiya
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import uploader from '../components/uploader'

  export default {
    name: 'transliterator',

    data() {
      return {
        actions: [
          {label: 'Кирилл - Lotin', id: 1},
          {label: 'Lotin - Кирилл', id: 2},
          {label: 'Lotin - Yangi Lotin', id: 3},
          {label: 'Yangi Lotin - Lotin', id: 4},
          {label: 'Кирилл - Yangi Lotin', id: 5},
          {label: 'Yangi Lotin - Кирилл', id: 6},
        ],
        transliterating: false,
        result: '',
        resultFiles: [],
        form: {
          action: null,
          files: [],
          text: '',
        },
      }
    },

    computed: {
      isValidForm() {
        return this.form.files.length || this.form.text
      },
    },

    methods: {
      clip() {
        navigator.clipboard.writeText(this.result).then(() => {
          this.$root.$emit('notify:success', 'Nusxalandi!')
        }, (err) => {
          this.$root.$emit('notify:success', 'Xatolik!')
        })
      },

      process() {
        if (this.isValidForm && !this.transliterating) {
          let data = new FormData()
          for (let x in this.form) {
            if (x === 'files') {
              this.form.files.forEach(f => {
                data.append('files[]', f)
              })
            } else {
              data.append(x, this.form[x])
            }
          }

          this.transliterating = true
          this.$http.post('transliterate', data)
                    .then(({data}) => {
                      if (data.status === 'ok') {
                        if (this.form.files.length) {
                          this.resultFiles = data.result
                        } else {
                          this.result = data.result
                        }
                        this.$root.$emit('notify:success', 'Bajarildi')
                        this.form.files = []
                      } else {
                        this.$root.$emit('notify:fail', data.message)
                      }
                    })
                    .catch((err) => {
                      this.$root.$emit('notify:fail', err.message)
                    })
                    .finally(() => {
                      this.transliterating = false
                    })
        }
      },

      getDownloadUrl(fileUrl) {
        return process.env.VUE_APP_API_URL + fileUrl
      }
    },

    mounted() {
      this.form.action = this.actions[0].id
    },

    components: {
      uploader
    }
  }
</script>
