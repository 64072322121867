import VueAxios from 'vue-axios'
import axios from 'axios'

export default {
  install(Vue) {
    const http = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      timeout: 60_000,
    });

    http.interceptors.request.use(function (config) {
      config.headers['Accept'] = 'application/json'
      return config;
    }, null);

    Vue.use(VueAxios, http)
  }
}
