<template>
    <v-container>
        <v-row justify="center" align-content="center" :class="['dropZone', dragging ? 'dropZone-over' : '']">
            <v-col cols="4" v-if="!files.length">
                <div class="d-flex">
                    <v-icon left color="teal lighten-3" x-large>fa-cloud-upload-alt</v-icon>
                    <div @dragenter="dragging = true"
                         @dragleave="dragging = false"
                         class="ml-2"
                    >
                        <div class="text--disabled" @drag="onChange">
                            <span class="fa fa-cloud-upload dropZone-title"></span>
                            <span class="dropZone-title">Faylni sudrab yuklang yoki bosing</span>
                            <div class="dropZone-upload-limit-info">
                                <div>Kengaytmalar: .docx, .xlsx, .txt</div>
                                <div>Maksimal fayl hajmi: 5MB</div>
                            </div>
                        </div>
                        <input type="file" @change="onChange">
                    </div>
                </div>
            </v-col>
            <v-col v-else class="dropZone-uploaded">
                <div class="d-flex justify-center">
                    <v-icon left color="teal lighten-3" x-large>fa-file</v-icon>
                    <div class="mr-3">
                        <span class="dropZone-title">Fayl tanlandi</span>
                        <div v-for="file in files" :key="file.name" class="dropZone-upload-limit-info">
                            <div>{{file.name}}</div>
                        </div>
                    </div>
                    <v-icon right depressed small color="error" @click="removeFile">fa fa-times</v-icon>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: 'uploader',

        props: {
            files: {
                required: true,
                type: Array,
            }
        },

        data() {
            return {
                dragging: false,

                supportedExtensions: [
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'text/plain',
                ]
            }
        },

        methods: {
            onChange(e) {
                let files = e.target.files || (e.dataTransfer || {files: []}).files
                if (files.length) {
                    this.createFile(files)
                }

                this.dragging = false;
            },

            createFile(files) {
                let file = files[0]
                if (this.supportedExtensions.indexOf(file.type) === -1) {
                    this.$root.$emit('notify:fail', 'Fayl kengaytmasi xato!')
                    this.dragging = false
                } else if (file.size > 5_000_000) {
                    this.$root.$emit('notify:fail', 'Fayl hajmi limitdan ortiq!')
                } else {
                    this.$emit('update:files', [file])
                }
            },

            removeFile() {
                this.$emit('update:files', [])
            }
        },

        computed: {
            extension() {
                let file = this.files[0]
                return file ? file.name.split('.').pop() : '';
            }
        }
    };
</script>

<style>
    .dropZone {
        height: 30vh;
        position: relative;
        border: 2px dashed #eee;
    }

    .dropZone:hover {
        border: 2px dashed #aaa;
    }

    .dropZone-info {
        color: #A8A8A8;
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translate(0, -50%);
        text-align: center;
    }

    .dropZone-title {
        color: #787878;
    }

    .dropZone input {
        position: absolute;
        cursor: pointer;
        top: 0px;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    .dropZone-upload-limit-info {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
    }
</style>